/*  rolling-noise
============================================================================= */

.nm-rolling-noise-1,
.nm-rolling-noise-2,
.nm-rolling-noise-3 {
	background-repeat: no-repeat;
	color: transparent;
	display: inline-block;
	font: 0/0 serif;
	overflow: visible;
	text-shadow: none;
	vertical-align: top;
}

.nm-rolling-noise-1::before,
.nm-rolling-noise-2::before,
.nm-rolling-noise-3::before {
	content: "";
	display: inline-block;
	height: 20px;
	width: 23px;
}