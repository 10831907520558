/*	module - preconfiguration-teaser
============================================================================= */
[page-type=widescreen] .nm-module-preconfiguration-teaser {
	width: 92%;
}

.nm-module-preconfiguration-teaser {
	cursor: url("data:image/png;base64,AAACAAEAICACAAcABQAwAQAAFgAAACgAAAAgAAAAQAAAAAEAAQAAAAAAAAEAAAAAAAAAAAAAAgAAAAAAAAAAAAAA////AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD8AAAA/AAAAfwAAAP+AAAH/gAAB/8AAA//AAAd/wAAGf+AAAH9gAADbYAAA2yAAAZsAAAGbAAAAGAAAAAAAAA//////////////////////////////////////////////////////////////////////////////////////gH///4B///8Af//+AD///AA///wAH//4AB//8AAf//AAD//5AA///gAP//4AD//8AF///AB///5A////5///8="), auto;
	margin: 0 auto 42px;
	overflow: hidden;
	position: relative;
}

/* Show swipe cursor only when dot navigation is visible */
.nm-module-preconfiguration-teaser .nm-preconfig-list-single {
	cursor: default;
}

.nm-module-preconfiguration-teaser .nm-preconfig-teaser-peephole {
	min-height: 286px; /* prevents the nm-dot navi from jumping around if images are not displayed */
	overflow: hidden;
	position: relative;
}

.nm-module-preconfiguration-teaser .nm-preconfiguration-teaser-headline {
	font-weight: var(--font-weight-bold);
	margin-bottom: 12px; /* --space-s */
}

.nm-module-preconfiguration-teaser .nm-preconfiguration-teaser-slide-image,
.nm-module-preconfiguration-teaser .nm-preconfiguration-teaser-slide-text {
	width: 100%;
}

.nm-module-preconfiguration-teaser .nm-preconfiguration-teaser-slide-text {
	position: relative;
}

.nm-module-preconfiguration-teaser .nm-preconfiguration-teaser-slide-image .nm-el-inner-img {
	background-position: center bottom;
	background-repeat: no-repeat;
	background-size: contain;
	height: 0;
	overflow: hidden;
	padding-bottom: 56.25%;
	width: 100%;
}

.nm-module-preconfiguration-teaser .nm-preconfiguration-teaser-slide-headline {
	box-sizing: border-box;
	font-weight: var(--font-weight-bold);
	margin-bottom: 12px; /* --space-s */
	padding: 0 20px;
	text-align: center;
}

.nm-module-preconfiguration-teaser .nm-md-preconfig-teaser-slide {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	position: relative;
	width: 100%;
}

.nm-module-preconfiguration-teaser .nm-preconfiguration-teaser-slide-list {
	margin-bottom: 24px; /* --space-l */
}

.nm-module-preconfiguration-teaser .nm-preconfiguration-teaser-button::after {
	align-items: center;
	content: attr(data-text);
	display: inline-flex;
	min-height: 30px;
	position: relative;
}

.nm-module-preconfiguration-teaser .nm-md-preconfig-teaser-custom-configuration .nm-preconfiguration-teaser-button::after {
	content: attr(data-text-new);
}

.nm-module-preconfiguration-teaser .nm-preconfiguration-teaser-button span {
	display: inline-block;
	text-indent: -9999px;
	width: 0;
}

.nm-module-preconfiguration-teaser .nm-preconfiguration-teaser-thumbs-wrap {
	display: flex;
	justify-content: center;
	margin-top: 24px;
	text-align: center;
	width: 100%;
}

.nm-module-preconfiguration-teaser .nm-preconfiguration-teaser-thumbs {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	max-width: 972px;
	width: 100%;
}

.nm-module-preconfiguration-teaser .nm-preconfiguration-teaser-thumbnail {
	display: inline-block;
	margin-left: 3px;
	position: relative;
	z-index: 1;
}

.nm-module-preconfiguration-teaser .nm-preconfiguration-teaser-thumbnail:first-child {
	margin-left: 0;
}

.nm-module-preconfiguration-teaser .nm-preconfiguration-teaser-thumbnail::before {
	background-color: #000;
	content: "";
	display: block;
	height: 8px;
	opacity: .2;
	width: 20px;
}

.nm-module-preconfiguration-teaser .nm-preconfiguration-teaser-thumbnail.nm-selected,
.nm-module-preconfiguration-teaser .nm-preconfiguration-teaser-thumbnail:hover {
	z-index: 2;
}

.nm-module-preconfiguration-teaser .nm-preconfiguration-teaser-thumbnail:hover::before {
	opacity: .5;
}

.nm-module-preconfiguration-teaser .nm-preconfiguration-teaser-thumbnail.nm-selected::before {
	opacity: .8;
}

.nm-module-preconfiguration-teaser .nm-preconfiguration-teaser-thumbnail .nm-preconfiguration-teaser-thumbnail-image {
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	display: none;
	height: 0;
	line-height: 0;
	margin-bottom: 15px;
	overflow: hidden;
	padding-bottom: 40%;
	position: relative;
	width: 100%;
}

.nm-module-preconfiguration-teaser .nm-preconfiguration-teaser-thumbnail-title {
	display: none;
	margin-bottom: 10px;
	text-align: center;
	width: 100%;
}

.nm-module-preconfiguration-teaser .nm-slide-animation-start-left,
.nm-module-preconfiguration-teaser .nm-slide-animation-start-right {
	overflow-x: hidden;
	width: 200%;
}

.nm-module-preconfiguration-teaser .nm-slide-animation-start-left .nm-md-preconfig-teaser-slide,
.nm-module-preconfiguration-teaser .nm-slide-animation-start-right .nm-md-preconfig-teaser-slide {
	width: 50%;
}

.nm-module-preconfiguration-teaser .nm-slide-animation-start-left .nm-md-preconfig-teaser-slide {
	float: left;
}

.nm-module-preconfiguration-teaser .nm-slide-animation-start-right .nm-md-preconfig-teaser-slide {
	float: right;
}

.nm-module-preconfiguration-teaser .nm-preconfiguration-teaser-slide-arrows {
	height: 0;
	left: 0;
	position: absolute;
	top: -6px;
	width: 100%;
	z-index: 1;
}

.nm-module-preconfiguration-teaser .nm-preconfiguration-teaser-slide-prev,
.nm-module-preconfiguration-teaser .nm-preconfiguration-teaser-slide-next {
	cursor: pointer;
	height: 48px;
	position: absolute;
	top: 0;
	width: 48px;
}

.nm-module-preconfiguration-teaser .nm-preconfiguration-teaser-slide-prev.nm-hidden,
.nm-module-preconfiguration-teaser .nm-preconfiguration-teaser-slide-next.nm-hidden {
	display: none;
}

.nm-module-preconfiguration-teaser .nm-preconfiguration-teaser-slide-prev .nm-icon-back-large,
.nm-module-preconfiguration-teaser .nm-preconfiguration-teaser-slide-next .nm-icon-forward-large {
	height: 48px;
	width: 48px;
}

.nm-module-preconfiguration-teaser .nm-preconfiguration-teaser-slide-prev {
	left: -16px;
}

.nm-module-preconfiguration-teaser .nm-preconfiguration-teaser-slide-next {
	right: -16px;
}

/*	for old templates 1280px
============================================================================= */

/* 3col for breakpoint 768px */
@media all and (min-width: 835px) {
	body:not([page-type=widescreen]) .nm-module-preconfiguration-teaser {
		margin-bottom: 48px;
	}

	body:not([page-type=widescreen]) .nm-module-preconfiguration-teaser .nm-preconfiguration-teaser-slide-headline {
		padding: 0;
		text-align: left;
	}

	body:not([page-type=widescreen]) .nm-module-preconfiguration-teaser .nm-preconfiguration-teaser-slide-arrows {
		display: none;
	}

	body:not([page-type=widescreen]) .nm-module-preconfiguration-teaser .nm-preconfiguration-teaser-slide-image {
		width: 56%;
	}

	body:not([page-type=widescreen]) .nm-module-preconfiguration-teaser .nm-preconfiguration-teaser-slide-text {
		width: 40%;
	}

	body:not([page-type=widescreen]) .nm-module-preconfiguration-teaser .nm-preconfiguration-teaser-thumbs-wrap {
		margin-top: 30px;
	}

	body:not([page-type=widescreen]) .nm-module-preconfiguration-teaser .nm-preconfiguration-teaser-thumbnail::before {
		display: none;
	}

	body:not([page-type=widescreen]) .nm-module-preconfiguration-teaser .nm-preconfiguration-teaser-thumbnail {
		margin-left: 0;
		max-width: 320px;
		width: calc((100% - 12px) / 3);
	}

	body:not([page-type=widescreen]) .nm-module-preconfiguration-teaser .nm-preconfiguration-teaser-thumbnail.nm-selected .nm-preconfiguration-teaser-thumbnail-title,
	body:not([page-type=widescreen]) .nm-module-preconfiguration-teaser .nm-preconfiguration-teaser-thumbnail:hover .nm-preconfiguration-teaser-thumbnail-title {
		font-family: AudiTypeWide, sans-serif;
		font-weight: var(--font-weight-bold);
		text-decoration: underline;
	}

	body:not([page-type=widescreen]) .nm-module-preconfiguration-teaser .nm-preconfiguration-teaser-thumbnail .nm-preconfiguration-teaser-thumbnail-image,
	body:not([page-type=widescreen]) .nm-module-preconfiguration-teaser .nm-preconfiguration-teaser-thumbnail .nm-preconfiguration-teaser-thumbnail-title {
		display: block;
	}
}

/* 3col for breakpoint 1024px */
@media all and (min-width: 1113px) {
	body:not([page-type=widescreen]) .nm-module-preconfiguration-teaser {
		margin-bottom: 60px;
	}
}

/*	for new templates 1920px
============================================================================= */

@media all and (min-width: 768px) {
	[page-type=widescreen] .nm-module-preconfiguration-teaser {
		margin-bottom: 48px;
	}

	[page-type=widescreen] .nm-module-preconfiguration-teaser .nm-preconfiguration-teaser-slide-headline {
		padding: 0;
		text-align: left;
	}

	[page-type=widescreen] .nm-module-preconfiguration-teaser .nm-preconfiguration-teaser-slide-arrows {
		display: none;
	}

	[page-type=widescreen] .nm-module-preconfiguration-teaser .nm-preconfiguration-teaser-slide-image {
		width: 56%;
	}

	[page-type=widescreen] .nm-module-preconfiguration-teaser .nm-preconfiguration-teaser-slide-text {
		width: 40%;
	}

	[page-type=widescreen] .nm-module-preconfiguration-teaser .nm-preconfiguration-teaser-thumbs-wrap {
		margin-top: 30px;
	}

	[page-type=widescreen] .nm-module-preconfiguration-teaser .nm-preconfiguration-teaser-thumbnail::before {
		display: none;
	}

	[page-type=widescreen] .nm-module-preconfiguration-teaser .nm-preconfiguration-teaser-thumbnail {
		margin-left: 0;
		max-width: 320px;
		width: calc((100% - 12px) / 3);
	}

	[page-type=widescreen] .nm-module-preconfiguration-teaser .nm-preconfiguration-teaser-thumbnail.nm-selected .nm-preconfiguration-teaser-thumbnail-title,
	[page-type=widescreen] .nm-module-preconfiguration-teaser .nm-preconfiguration-teaser-thumbnail:hover .nm-preconfiguration-teaser-thumbnail-title {
		font-family: AudiTypeWide, sans-serif;
		font-weight: var(--font-weight-bold);
		text-decoration: underline;
	}

	[page-type=widescreen] .nm-module-preconfiguration-teaser .nm-preconfiguration-teaser-thumbnail .nm-preconfiguration-teaser-thumbnail-image,
	[page-type=widescreen] .nm-module-preconfiguration-teaser .nm-preconfiguration-teaser-thumbnail .nm-preconfiguration-teaser-thumbnail-title {
		display: block;
	}
}

@media all and (min-width: 1024px) {
	[page-type=widescreen] .nm-module-preconfiguration-teaser {
		margin-bottom: 60px;
	}
}

@media all and (min-width: 1440px) {
	[page-type=widescreen] .nm-module-preconfiguration-teaser {
		margin-bottom: 78px;
	}

	[page-type=widescreen] .nm-module-preconfiguration-teaser .nm-preconfiguration-teaser-thumbs-wrap {
		margin-top: 36px;
	}

	[page-type=widescreen] .nm-module-preconfiguration-teaser ul.nm-preconfiguration-teaser-slide-list {
		font-size: 1.8rem;
		line-height: 3rem;
	}

	[page-type=widescreen] .nm-module-preconfiguration-teaser ul.nm-preconfiguration-teaser-slide-list li {
		margin-bottom: var(--space-m);
	}

	[page-type=widescreen] .nm-module-preconfiguration-teaser ul.nm-preconfiguration-teaser-slide-list li::before {
		top: 15px;
	}

	[page-type=widescreen] .nm-module-preconfiguration-teaser .nm-preconfiguration-teaser-thumbs {
		max-width: 100%;
	}
}

@media all and (min-width: 1920px) {
	[page-type=widescreen] .nm-module-preconfiguration-teaser {
		margin-bottom: 96px;
	}

	[page-type=widescreen] .nm-module-preconfiguration-teaser .nm-preconfiguration-teaser-thumbs-wrap {
		margin-top: 42px;
	}
}
