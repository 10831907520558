/*	module - visualizer
============================================================================= */

.nm-module.nm-module-visualizer {
	margin-bottom: 0;
}

.nm-module-visualizer {
	padding-bottom: 42px;
	position: relative;
}

/* HIDE / SHOW Module */
.nm-module-visualizer .nm-visualizer-slide {
	position: absolute;
	top: 0;
	transition: opacity .25s;
	transition-delay: .25s;
	width: 100%;
}

.nm-module-visualizer .nm-visualizer-slide.hidden {
	height: 0;
	opacity: 0;
	z-index: 0;
}

.nm-module-visualizer .nm-visualizer-slide.active {
	height: auto;
	opacity: 1;
	z-index: 4;
}

/* Video Player */
.nm-module-visualizer .nm-visualizer-slides,
.nm-module-visualizer .nm-visualizer-slide,
.nm-module-visualizer .nm-mediaplayer,
.nm-module-visualizer .nm-el-image {
	padding-bottom: 38.461538461538%;
}

.nm-module-visualizer .nm-el-image {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 0;
	overflow: hidden;
	width: 100%;
}

.nm-module-visualizer .nm-visualizer-slides {
	margin-bottom: 30px;
	overflow: hidden;
	position: relative;
}

.nm-module-visualizer .nm-controls-wrap .nm-video-loader {
	background: #fff;
}

.nm-module-visualizer .nm-video-loader .nm-loader-loaded {
	background: #6d7579;
}

.nm-module-visualizer .nm-visualizer-loader {
	background-color: rgba(255, 255, 255, .75);
	bottom: 7px;
	display: none;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

/* Config Button */
.nm-module-visualizer .nm-button-standard {
	margin-top: 24px;
}

/* Tiles */
.nm-module-visualizer .nm-visuzalizer-tile {
	cursor: pointer;
	height: 52px;
	margin-left: 3px;
	overflow: visible;
	padding-top: calc(73px - 52px);
	transition: all .3s ease-in-out;
	width: 52px;
	z-index: 1;
}

.nm-module-visualizer .nm-visuzalizer-tile:first-child {
	margin-left: 0;
}

.nm-module-visualizer .nm-visuzalizer-tile.nm-selected {
	height: 73px;
	padding-top: 0;
	width: 73px;
	z-index: 2;
}

.nm-module-visualizer .nm-visualizer-tiles-block {
	display: flex;
	flex-flow: row wrap;
	position: relative;
}

.nm-module-visualizer .nm-visualizer-tiles-block-bar {
	background-color: #d9d9d9;
	height: 2px;
	left: 0;
	position: absolute;
	top: calc(73px + 12px);
	width: 100%;
	z-index: 3;
}

.nm-module-visualizer .nm-visualizer-tile-active-bar {
	background-color: #000;
	height: 2px;
	left: 0;
	position: absolute;
	top: 0;
	transition: all .25s ease-in-out;
	width: 73px;
}

.nm-module-visualizer .nm-visuzalizer-tile-image img {
	height: auto;
	width: 100%;
}

.nm-module-visualizer .nm-visualizer-tile-info {
	background: #fff;
	display: block;
	left: 0;
	opacity: 0;
	position: absolute;
	top: calc(73px + 20px);
	transition: opacity .25s ease-in;
	width: 100%;
}

.nm-module-visualizer .nm-visuzalizer-tile:hover .nm-visualizer-tile-info,
.nm-module-visualizer .nm-visuzalizer-tile.nm-selected .nm-visualizer-tile-info {
	opacity: 1;
	transition: opacity .4s ease-out;
}

.nm-module-visualizer .nm-visuzalizer-tile:hover {
	z-index: 3;
}

.nm-module-visualizer .nm-visualizer-tiles-color {
	margin-bottom: 18px;
	min-height: 111px;
}

.nm-module-visualizer .nm-visualizer-tiles-rim {
	min-height: 147px;
}

.nm-module-visualizer .nm-visualizer-configuration-wrap {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	margin: 0 auto;
	width: auto;
}

[page-type="widescreen"] .nm-module-visualizer .nm-visualizer-configuration-wrap {
	max-width: 92%;
}

.nm-module-visualizer .nm-visualizer-tiles {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
}

.nm-module-visualizer .nm-visualizer-tiles-block {
	max-width: calc(73px + (4 * 55px));
}

/* video player */

.nm-module-visualizer .nm-prevent-video-click-visualizer {
	display: block;
	height: 100%;
	position: absolute;
	width: 100%;
}

.nm-module-visualizer .nm-mediaplayer:not(.active-fs) .nm-controls-wrap-video,
.nm-module-visualizer .nm-mediaplayer:hover:not(.active-fs) .nm-controls-wrap-video {
	bottom: -72px;
}

/* TODO: modify, once overlay and basis-button is defined in core */
.nm-module-visualizer .nm-video-player-overlay {
	background-color: #000;
	height: 100%;
	opacity: .3;
	position: absolute;
	top: 0;
	transition: opacity .4s ease-out;
	width: 100%;
	z-index: 2;
}

.nm-module-visualizer .nm-basis-video-play-button {
	background: none;
	border: none;
	cursor: pointer;
	height: 72px;
	left: 0;
	margin: 0 auto;
	opacity: 1;
	padding: 0;
	position: absolute;
	right: 0;
	top: calc(50% - 36px);
	transition: opacity .4s ease-out;
	width: 72px;
	z-index: 5;
}

.nm-module-visualizer .nm-video-playing .nm-basis-video-play-button {
	height: 0;
}

.nm-module-visualizer .nm-video-playing .nm-basis-video-play-button,
.nm-module-visualizer .nm-video-playing .nm-video-player-overlay {
	opacity: 0;
	transition: opacity .4s ease-in;
}

/* TODO : END */

@media all and (min-width: 375px) {
	.nm-module-visualizer .nm-visualizer-tiles-block {
		margin-left: 21px;
		margin-right: 21px;
	}
}

@media all and (min-width: 768px) {
	.nm-module-visualizer {
		padding-bottom: 48px;
	}

	.nm-module-visualizer .nm-visualizer-configuration-wrap,
	.nm-module-visualizer .nm-visualizer-tiles {
		justify-content: space-between;
	}

	.nm-module-visualizer .nm-visualizer-tiles {
		flex-grow: 1;
	}

	.nm-module-visualizer .nm-visualizer-tiles-block {
		margin-left: 0;
		margin-right: 0;
	}
}

@media all and (min-width: 1024px) {
	.nm-module-visualizer {
		padding-bottom: 60px;
	}
}

@media all and (min-width: 1440px) {
	.nm-module-visualizer {
		padding-bottom: 78px;
	}

	[page-type="widescreen"] .nm-module-visualizer .nm-visualizer-slides {
		margin-bottom: 36px;
	}

	[page-type="widescreen"] .nm-module-visualizer .nm-button-standard {
		margin-top: 37px;
	}

	[page-type="widescreen"] .nm-module-visualizer .nm-visualizer-configuration-wrap {
		flex-flow: row wrap;
		justify-content: center;
	}

	[page-type="widescreen"] .nm-module-visualizer .nm-visualizer-tiles-rim {
		margin-left: 78px;
		margin-right: 78px;
	}
}

@media all and (min-width: 1920px) {
	.nm-module-visualizer {
		padding-bottom: 96px;
	}

	[page-type="widescreen"] .nm-module-visualizer .nm-visualizer-slides {
		margin-bottom: 42px;
	}

	[page-type="widescreen"] .nm-module-visualizer .nm-visualizer-tiles-rim {
		margin-left: 96px;
		margin-right: 96px;
	}
}
