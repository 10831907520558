/**************************************************************************
	Mediathek-Stage
***************************************************************************/

/* scope */
.nm-module-mediathek-stage {
	overflow: hidden;
	position: relative;
}

.nm-module-mediathek-stage .nm-module-mediathek-stage-media-wrapper {
	height: 0;
	padding-bottom: 56.25%;
	text-align: center;
}

.nm-module-mediathek-stage .nm-module-mediathek-stage-media-wrapper[data-format="square"] {
	padding-bottom: 100%;
}

.nm-module-mediathek-stage .nm-module-mediathek-stage-media {
	margin: 0 auto;
	max-width: 100%;
}

.nm-module-mediathek-stage .nm-module-mediathek-stage-media-render-image {
	margin: 0 auto;
	max-width: 100%;
	padding-bottom: 56.25%;
}

.nm-module-mediathek-stage .nm-module-mediathek-stage-bs-remote {
	margin-top: calc((56.25% / 2) - (20px / 2)); /* padding-bottom media-wrapper minus the arrowsize */
}

.nm-module-mediathek-stage .nm-module-mediathek-stage-dots-navi {
	bottom: 0;
	position: absolute;
	text-align: center;
	width: 100%;
	z-index: 16;
}

.nm-module-mediathek-stage .nm-module-mediathek-stage-dots-navi-listitem {
	display: inline-block;
}

.nm-module-mediathek-stage .nm-module-mediathek-stage-dot {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDowNzc1NkMxN0VCRkExMUU0OTIxOUYyNzY5QkNCRkI3QyIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDowNzc1NkMxOEVCRkExMUU0OTIxOUYyNzY5QkNCRkI3QyI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjA3NzU2QzE1RUJGQTExRTQ5MjE5RjI3NjlCQ0JGQjdDIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjA3NzU2QzE2RUJGQTExRTQ5MjE5RjI3NjlCQ0JGQjdDIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+rVFjTQAAALRJREFUeNpi/P//PwMpgBFNgxIQm0JpELgHxKehNBgwISl2/vjxY1B3d/dXRUXFfSAMYoPEQHJwVSAbgFjp7du3JSoqKhZAIWNkDBIDyYHUgNTCbDCdO3fujTt37vxGdzNIDCQHdSrcD5UgJzx48OAPNo/KyMiwPH78GOSsNiYGEgFMw72srCxRXIry8vJAcnfJ8jRMAwg7gyS6urp8FBQUzEEYxIYqdoapIzniGElNGgABBgD9N4UAcWCrPwAAAABJRU5ErkJggg==");
	cursor: pointer;
	display: inline-block;
	height: 12px;
	margin: 0 4px;
	vertical-align: top;
	width: 12px;
}

.nm-module-mediathek-stage .nm-module-mediathek-stage-dot.nm-bs-selected {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDowNzc1NkMxM0VCRkExMUU0OTIxOUYyNzY5QkNCRkI3QyIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDowNzc1NkMxNEVCRkExMUU0OTIxOUYyNzY5QkNCRkI3QyI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjA3NzU2QzExRUJGQTExRTQ5MjE5RjI3NjlCQ0JGQjdDIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjA3NzU2QzEyRUJGQTExRTQ5MjE5RjI3NjlCQ0JGQjdDIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+QmWFSAAAALFJREFUeNqUkrEKwyAURaPSqVOGrhk799PaD+raUfEP3Lv7Ew6CYibFvismdCk2Dy4+4jkKvrBa67SVEGKm5UI590+J4kopfocgcM5PlEUp9fDevwlICHop5R17YNrhXVistc/6o7AHpgnUzDilDgq3g4VwxdUjAQxYCLeccxoJYMDy6c9ijLUFwhpjtCMhhAAmQXDGmNdI6Iw7/qzfg9Nab4NbEfT9OffBsaO/xkeAAQC/6hUWzWQARgAAAABJRU5ErkJggg==");
}

@media (min-width: 480px) {
	.nm-module-mediathek-stage .nm-bs-remote .nm-stage-browse {
		display: block;
	}
}

@media (min-width: 750px) {
	.nm-module-mediathek-stage {
		margin-top: 0;
	}
}
